<template>
    <div class="manage">
		<div class="tabs">
			<span class="tab-one" :class="{selected:tab==item.value}" v-for="item in tabs" v-show="item.auth" @click = tabActive(item)>{{item.name}}</span>
		</div>
        <div class="body">
			<company v-model="show.company" @to-user="toUser"></company>
			<user v-model="show.user" :dept_id="dept_id"></user>
			<device v-model="show.device"></device>
			<rule v-model="show.rule"></rule>
        </div>
		<p class="van-safe-area-bottom"></p>
    </div>
</template>

<script>
    import {defineAsyncComponent,defineComponent, onMounted, toRefs, reactive,computed,watch} from "vue"
	import {useStore} from "vuex"
	import * as dd from 'dingtalk-jsapi';
    export default defineComponent({
        name: "manage",
        setup(props, {emit}) {
        	const store = useStore();
            const state = reactive({
				tabs:[
					{
						name:'企业列表',
						auth:false,
						value:'company'
					},
					{
						name:'监控点列表',
						auth:true,
						value:'device'
					},
					{
						name:'用户列表',
						auth:true,
						value:'user'
					},
					{
						name:'报警规则',
						auth:true,
						value:'rule'
					}
				],
				tab:'device',
				list:[],
				page:1,
				page_size:15,
				total:0,
				next_time:Date.now(),
				isPc:dd.pc,
				show:{
					company:false,
					user:false,
					device:false,
					rule:false
				},
				dept_id:'',
            });
			const current_user = computed(()=>{
				return store.state.user.current_user
			});
			watch(()=>current_user.value,(val)=>{
				init(val);
			},{immediate:true,deep:true});

			function tabActive(item){
            	if(state.tab!=item.value){
					state.show[state.tab] = false;
					state.tab = item.value;
					state.show[item.value] = true;
				}
			}
			function toUser(item) {
				state.dept_id = item.dept_id;
				state.show.company = false;
				state.tab = "user";
				state.show.user = true;
			}
			function init(val) {
				if(val.app_admin==0){
					return;
				}
				if(val.app_admin==1){
					state.show.company = true;
					state.tabs[0].auth = true;
					state.tab = "company";
				}
				if(val.app_admin==2||val.app_admin==3){
					state.show.company = false;
					state.tabs[0].auth = false;
					state.tab = "device";
					state.show.device = true;
				}
			}
			onMounted(()=>{

			});
            return {
                ...toRefs(state),
				current_user,
				tabActive,
				toUser
            }
        },
		components:{
        	company:defineAsyncComponent(()=>import('@/components/manage/company.vue')),
        	user:defineAsyncComponent(()=>import('@/components/manage/user.vue')),
        	device:defineAsyncComponent(()=>import('@/components/manage/device.vue')),
        	rule:defineAsyncComponent(()=>import('@/components/manage/rule.vue'))
		}
    })
</script>

<style scoped lang="less">
    .manage {
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
		.tabs{
			flex-shrink: 0;
			display: flex;
			.tab-one{
				position: relative;
				flex: 1;
				display: inline-flex;
				height: .5rem;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
			.selected{
				color: #2ca0f7;
				&::after{
					content: '';
					position: absolute;
					bottom: .05rem;
					width: .4rem;
					height: .03rem;
					border-radius: .04rem;
					background-color: #2ca0f7;
				}
			}
		}
        .body {
            flex: 1;
			display: flex;
			flex-direction: column;
			border-top: .03rem solid #eef2f5;
			overflow: hidden;
			background-color: #fff;
        }
    }
</style>
